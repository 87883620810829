import { ColumnVisibilityState, GridState } from "@ag-grid-community/core";
import { pick, snakeCase } from "lodash";

import {
  BULK_ACTIONS_CHECKBOX_COLUMN,
  COLUMN_CONFIGURATION,
  PINNED_COLUMNS,
} from "./columnDefinitions.js";
import {
  ProjectsTableColumnId,
  ProjectsTablePersistedGridState,
} from "./types.js";

const getColumnOrderGridState = ({
  columnOrder,
  selectable,
}: {
  selectable: boolean;
  columnOrder: ProjectsTableColumnId[];
}): string[] =>
  selectable
    ? [BULK_ACTIONS_CHECKBOX_COLUMN.colId, ...columnOrder]
    : [...columnOrder];

const getPinnedColumnInitialState = ({
  selectable,
}: {
  selectable: boolean;
}): {
  leftColIds: string[];
  rightColIds: string[];
} => {
  const pinnedColumnInitialState: {
    leftColIds: string[];
    rightColIds: string[];
  } = {
    leftColIds: [],
    rightColIds: [],
  };

  if (selectable) {
    pinnedColumnInitialState.leftColIds.push(
      BULK_ACTIONS_CHECKBOX_COLUMN.colId,
    );
  }

  for (const pinnedColumnId of PINNED_COLUMNS.values()) {
    const columnConfig = COLUMN_CONFIGURATION[pinnedColumnId];

    if (columnConfig.pinned) {
      let pinned = columnConfig.pinned;
      pinned = pinned === true ? "left" : pinned;

      const key = getHandedColumnPinningKey(pinned);
      pinnedColumnInitialState[key].push(pinnedColumnId);
    }
  }

  return pinnedColumnInitialState;
};
const getColumnVisibilityInitialState = (
  colIds: (string | ProjectsTableColumnId)[],
): ProjectsTableColumnId[] => {
  const validColIds = filterInvalidIds(colIds);

  return validColIds.filter((colId) => !PINNED_COLUMNS.has(colId));
};

const PERSISTED_STATE_KEYS = ["columnVisibility"] as const;
export const extractPersistedGridState = (
  state: GridState,
): {
  columnVisibility: ColumnVisibilityState;
} => {
  const { columnVisibility = { hiddenColIds: [] } } = pick(
    state,
    PERSISTED_STATE_KEYS,
  );
  return { columnVisibility };
};

export const buildInitialGridState = ({
  columnOrder,
  defaultHiddenColIds,
  persisted,
  selectable,
}: {
  persisted: ProjectsTablePersistedGridState | undefined;
  columnOrder: ProjectsTableColumnId[];
  defaultHiddenColIds: ProjectsTableColumnId[];
  selectable: boolean;
}): GridState => {
  return {
    columnVisibility: {
      hiddenColIds: getColumnVisibilityInitialState(
        persisted?.columnVisibility?.hiddenColIds || defaultHiddenColIds,
      ),
    },
    columnPinning: getPinnedColumnInitialState({ selectable }),
    columnOrder: {
      orderedColIds: getColumnOrderGridState({ columnOrder, selectable }),
    },
  };
};

// Utils
function getHandedColumnPinningKey<T extends string>(
  handedness: T,
): `${T}ColIds` {
  return `${handedness}ColIds`;
}

export function filterInvalidIds(
  ids: (string | ProjectsTableColumnId)[],
): ProjectsTableColumnId[] {
  const getProjectsTableColumnIdKey = (id: (typeof ids)[0]): string => {
    return id === "lastViewedByMe"
      ? "RECENTLY_VIEWED"
      : snakeCase(id).toUpperCase();
  };
  const isValidColumnId = (
    colId: ProjectsTableColumnId | string,
  ): colId is ProjectsTableColumnId =>
    getProjectsTableColumnIdKey(colId) in ProjectsTableColumnId;

  return ids.filter(isValidColumnId);
}
