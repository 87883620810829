import { AgGridReact } from "@ag-grid-community/react";
import { HexOrder, OrderByDirection } from "@hex/common";
import React, {
  MutableRefObject,
  createContext,
  useCallback,
  useRef,
  useState,
} from "react";

import {
  HomeQueryParamsKey,
  useQueryParamGetter,
} from "../../../route/routes.js";
import { getDefaultHexOrderByDirection } from "../../home/shared/SortByHexOrder.js";
import {
  AUTOSIZE_COLUMNS,
  PROJECTS_TABLE_SORT_ORDER,
} from "../columnDefinitions.js";
import {
  ALL_COLUMNS_CHECKBOX_ID,
  SCREEN_READER_ONLY_CLASSNAME,
} from "../constants.js";
import { filterInvalidIds } from "../initialGridState.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "../ProjectsTable.generated.js";
import { getSortModelFromQueryParams } from "../sortModelUtils.js";
import {
  ColumnVisibilityCheckboxState,
  ProjectsTableColumnId,
  ProjectsTableColumnState,
} from "../types.js";

interface ProjectsTableContext {
  initializeCheckboxState: () => ColumnVisibilityCheckboxState;
  initializeColumnOrder: () => ProjectsTableColumnId[];
  handleColumnVisibilityChange: (args: {
    checked: HTMLInputElement["checked"];
    id: HTMLInputElement["id"];
  }) => void;
  agGridInstanceRef: MutableRefObject<
    AgGridReact<ProjectsTableSafeOrUnknownHexFragment>
  >;
  onGridReady: () => void;
  onGridPreDestroyed: () => void;
  isGridReady: boolean;
  purgeProjectsTableCache: () => void;
  handleColumnVisibilityReset: () => void;
  syncSortModel: (
    sortOrder: PROJECTS_TABLE_SORT_ORDER,
    sortDirection: OrderByDirection,
  ) => void;
}

export const ProjectsTableContext = createContext<ProjectsTableContext>(
  {} as ProjectsTableContext,
);
/**
 * Note: this is intentionally not wrapped with `React.memo` because this is intended
 * to be used as a parent component that accepts only `children` as a prop.
 */
export const ProjectsTableContextProvider: React.FC = ({ children }) => {
  const agGridInstanceRef =
    useRef<AgGridReact<ProjectsTableSafeOrUnknownHexFragment>>(null);
  const [isGridReady, setIsGridReady] = useState(false);

  const syncSortModel = useCallback(
    (sortOrder: PROJECTS_TABLE_SORT_ORDER, sortDirection: OrderByDirection) => {
      if (
        agGridInstanceRef.current == null ||
        agGridInstanceRef.current.api == null ||
        agGridInstanceRef.current.api.isDestroyed()
      ) {
        return;
      }
      const sortModel = getSortModelFromQueryParams({
        sort: sortOrder,
        direction: sortDirection,
      });

      if (sortModel.colId != null && sortModel.sort != null) {
        agGridInstanceRef.current?.api.applyColumnState({
          state: [
            {
              colId: sortModel.colId,
              sort: sortModel.sort,
            },
          ],
          defaultState: {
            sort: null,
          },
        });
      }
    },
    [],
  );
  const getQueryParams = useQueryParamGetter();
  const syncSortModelOnGridReady = useCallback(() => {
    const sortQueryParam = getQueryParams()
      .get(HomeQueryParamsKey.SORT)
      ?.split(":");
    const [sort, _direction] = sortQueryParam ?? [];
    // @TODO(WORK-1391) runtype for hex order and remove this cast
    const sortOrder = sort?.toUpperCase() as HexOrder;
    const maybeDirection = _direction?.toUpperCase();
    const direction =
      maybeDirection != null && OrderByDirection.guard(maybeDirection)
        ? maybeDirection
        : // @TODO (https://github.com/hex-inc/hex/pull/23554) pipe in getter for collection hex link sort
          getDefaultHexOrderByDirection(sortOrder);

    syncSortModel(sortOrder, direction);
  }, [getQueryParams, syncSortModel]);

  const onGridReady = useCallback<() => void>(() => {
    if (agGridInstanceRef.current != null) {
      setIsGridReady(true);

      syncSortModelOnGridReady();
    }
  }, [setIsGridReady, syncSortModelOnGridReady]);

  const onGridPreDestroyed = useCallback<() => void>(() => {
    setIsGridReady(false);
  }, []);

  const handleAutosizeColumns = useCallback(() => {
    agGridInstanceRef.current?.api.autoSizeColumns(AUTOSIZE_COLUMNS, false);
  }, []);

  const initializeCheckboxState =
    useCallback((): ColumnVisibilityCheckboxState => {
      const columnState: ProjectsTableColumnState[] =
        agGridInstanceRef.current?.api.getColumnState() ?? [];

      return columnState.reduce<ColumnVisibilityCheckboxState>(
        (accum, curr) => {
          accum[curr.colId as ProjectsTableColumnId] = {
            checked: !curr.hide,
            readonly: curr.pinned != null,
          };
          return accum;
        },
        {} as ColumnVisibilityCheckboxState,
      );
    }, []);

  const initializeColumnOrder = useCallback((): ProjectsTableColumnId[] => {
    const columnState: readonly ProjectsTableColumnState[] =
      agGridInstanceRef.current?.api.getColumnState() ?? [];

    const validColumnIds = filterInvalidIds(
      columnState.map(({ colId }) => colId),
    );

    // Filter out columns with no visible header
    return validColumnIds.filter((id) => {
      return (
        agGridInstanceRef.current?.api.getColumnDef(id)?.headerClass !==
        SCREEN_READER_ONLY_CLASSNAME
      );
    });
  }, []);

  const handleColumnVisibilityChange: ({
    checked,
    id,
  }: {
    checked: HTMLInputElement["checked"];
    id: HTMLInputElement["id"];
  }) => void = useCallback(
    ({ checked, id }) => {
      const colState = agGridInstanceRef.current?.api.getColumnState();

      if (id === ALL_COLUMNS_CHECKBOX_ID) {
        const update = colState
          ?.filter(({ pinned }) => pinned == null)
          .map(({ colId }) => ({ colId, hide: !checked }));

        agGridInstanceRef.current?.api.applyColumnState({
          state: update,
          defaultState: {
            hide: false,
          },
        });

        handleAutosizeColumns();

        return;
      }
      const targetColumnState = colState?.find(({ colId }) => colId === id);

      if (targetColumnState == null) {
        return;
      }

      const { hide: isHidden } = targetColumnState;

      const shouldChangeState = isHidden === checked;
      if (shouldChangeState) {
        agGridInstanceRef.current?.api.applyColumnState({
          state: [
            {
              colId: id,
              hide: !checked,
            },
          ],
        });
      }
    },
    [handleAutosizeColumns],
  );

  const handleColumnVisibilityReset = useCallback(() => {
    const colState = agGridInstanceRef.current?.api.getColumnState();
    const defaultHiddenColumns =
      agGridInstanceRef?.current?.props?.context?.defaultHiddenColumns;

    const update = colState?.map(({ colId, hide, pinned }) => ({
      colId,
      hide: pinned ? hide : defaultHiddenColumns.has(colId),
    }));

    agGridInstanceRef.current?.api.applyColumnState({
      state: update,
      defaultState: { hide: false },
    });

    handleAutosizeColumns();
  }, [handleAutosizeColumns]);

  const purgeProjectsTableCache = useCallback(() => {
    // instance is nullish when in card grid view
    if (
      agGridInstanceRef.current != null &&
      !agGridInstanceRef.current.api?.isDestroyed()
    ) {
      agGridInstanceRef.current.api.refreshServerSide({
        // full data refresh
        purge: true,
      });
    }
  }, []);

  const ctx = React.useMemo<ProjectsTableContext>(
    () => ({
      agGridInstanceRef: agGridInstanceRef as MutableRefObject<
        AgGridReact<ProjectsTableSafeOrUnknownHexFragment>
      >,
      isGridReady,
      onGridReady,
      initializeCheckboxState,
      initializeColumnOrder,
      handleColumnVisibilityChange,
      purgeProjectsTableCache,
      handleColumnVisibilityReset,
      onGridPreDestroyed,
      syncSortModel,
    }),
    [
      onGridReady,
      isGridReady,
      initializeCheckboxState,
      initializeColumnOrder,
      handleColumnVisibilityChange,
      purgeProjectsTableCache,
      handleColumnVisibilityReset,
      onGridPreDestroyed,
      syncSortModel,
    ],
  );

  return (
    <ProjectsTableContext.Provider value={ctx}>
      {children}
    </ProjectsTableContext.Provider>
  );
};
