export enum RenderContext {
  SIDE_BAR = "sideBar",
  LOGIC_VIEW = "logicView",
  DIFF_VIEW = "diffView",
  APP_VIEW = "appView",
  PUBLISHED_APP = "publishedApp",
  ELEMENT_MEASURER = "elementMeasurer",
  EXPLORE = "explore",
}

export enum AppRenderContext {
  CANVAS = "CANVAS",
  GRID = "GRID",
  STORY = "STORY",
}
