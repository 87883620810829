import { SortDirection as AgGridSortDirection } from "@ag-grid-community/core";
import { HexOrder, OrderByDirection, assertNever } from "@hex/common";

import { ViewCountDuration } from "../hex-list/ViewCountDuration.js";
import { getDefaultHexOrderByDirection } from "../home/shared/SortByHexOrder.js";

import { PROJECTS_TABLE_SORT_ORDER } from "./columnDefinitions.js";
import { ProjectsTableColumnId } from "./types";

const {
  APP_VIEWS,
  CATEGORIES,
  COLLECTION_ACCESS,
  CREATOR,
  DATE_ARCHIVED,
  DATE_CREATED,
  DATE_LAST_EDITED,
  DATE_LAST_PUBLISHED,
  DATE_PUBLISHED_APP_LAST_VIEWED,
  MY_ACCESS,
  NAME,
  OWNER,
  RECENTLY_VIEWED,
  STATUS,
} = ProjectsTableColumnId;

export const isLowercaseOrderByDirection = (
  str: string,
): str is Lowercase<OrderByDirection> => {
  return OrderByDirection.guard(str.toUpperCase());
};

type HexAGGRidSortDirection = Exclude<AgGridSortDirection, null>;

const convertOrderByDirectionToSortDirection = (
  direction: OrderByDirection,
): HexAGGRidSortDirection => {
  switch (direction) {
    case "ASC":
      return "asc";
    case "DESC":
    default:
      return "desc";
  }
};

export const getSortModelFromQueryParams = ({
  direction,
  sort,
}: {
  direction: OrderByDirection;
  sort: PROJECTS_TABLE_SORT_ORDER | undefined;
}): {
  colId: ProjectsTableColumnId;
  sort: HexAGGRidSortDirection;
  sortIndex: number;
} => {
  if (sort == null) {
    return {
      colId: RECENTLY_VIEWED,
      sort: convertOrderByDirectionToSortDirection(
        getDefaultHexOrderByDirection(HexOrder.RECENTLY_VIEWED),
      ),
      sortIndex: 0,
    };
  }

  const commonSortParams = {
    sort: convertOrderByDirectionToSortDirection(direction),
    sortIndex: 0,
  };

  switch (sort) {
    case HexOrder.LAST_EDIT:
      return {
        colId: DATE_LAST_EDITED,
        ...commonSortParams,
      };
    case HexOrder.LAST_PUBLISH:
      return {
        colId: DATE_LAST_PUBLISHED,
        ...commonSortParams,
      };
    case HexOrder.TOTAL_VIEW_COUNT:
    case HexOrder.LAST_SEVEN_DAYS_VIEW_COUNT:
    case HexOrder.LAST_FOURTEEN_DAY_VIEW_COUNT:
    case HexOrder.LAST_THIRTY_DAY_VIEW_COUNT:
      return {
        colId: APP_VIEWS,
        ...commonSortParams,
      };
    case HexOrder.RECENTLY_VIEWED:
      return {
        colId: RECENTLY_VIEWED,
        ...commonSortParams,
      };
    case HexOrder.CREATED_AT:
      return {
        colId: DATE_CREATED,
        ...commonSortParams,
      };
    case HexOrder.OWNER:
      return {
        colId: OWNER,
        ...commonSortParams,
      };
    case HexOrder.CREATOR:
      return {
        colId: CREATOR,
        ...commonSortParams,
      };
    case HexOrder.PROJECT_NAME:
      return {
        colId: NAME,
        ...commonSortParams,
      };
    case HexOrder.CATEGORY:
      return {
        colId: CATEGORIES,
        ...commonSortParams,
      };
    case HexOrder.STATUS:
      return {
        colId: STATUS,
        ...commonSortParams,
      };
    case HexOrder.ARCHIVED_AT:
      return {
        colId: DATE_ARCHIVED,
        ...commonSortParams,
      };
    case HexOrder.MY_ACCESS:
      return {
        colId: MY_ACCESS,
        ...commonSortParams,
      };
    case "COLLECTION_LINK_PROJECT_ROLE":
      return {
        colId: COLLECTION_ACCESS,
        ...commonSortParams,
      };
    case "APP_ONLY_RECENTLY_VIEWED":
      return {
        colId: DATE_PUBLISHED_APP_LAST_VIEWED,
        ...commonSortParams,
      };
    default:
      assertNever(sort, sort);
  }
};

export const getSortOrderFromColumnId = (
  colId: ProjectsTableColumnId | null,
  duration: ViewCountDuration = ViewCountDuration.ALL_TIME,
): PROJECTS_TABLE_SORT_ORDER | undefined => {
  switch (colId) {
    case DATE_LAST_EDITED:
      return HexOrder.LAST_EDIT;
    case DATE_LAST_PUBLISHED:
      return HexOrder.LAST_PUBLISH;
    case RECENTLY_VIEWED:
      return HexOrder.RECENTLY_VIEWED;
    case NAME:
      return HexOrder.PROJECT_NAME;
    case APP_VIEWS: {
      switch (duration) {
        case ViewCountDuration.ALL_TIME:
          return HexOrder.TOTAL_VIEW_COUNT;
        case ViewCountDuration.ONE_WEEK:
          return HexOrder.LAST_SEVEN_DAYS_VIEW_COUNT;
        case ViewCountDuration.TWO_WEEKS:
          return HexOrder.LAST_FOURTEEN_DAY_VIEW_COUNT;
        case ViewCountDuration.ONE_MONTH:
          return HexOrder.LAST_THIRTY_DAY_VIEW_COUNT;
        default:
          return undefined;
      }
    }
    case DATE_CREATED:
      return HexOrder.CREATED_AT;
    case OWNER:
      return HexOrder.OWNER;
    case CREATOR:
      return HexOrder.CREATOR;
    case CATEGORIES:
      return HexOrder.CATEGORY;
    case STATUS:
      return HexOrder.STATUS;
    case DATE_ARCHIVED:
      return HexOrder.ARCHIVED_AT;
    case MY_ACCESS:
      return HexOrder.MY_ACCESS;
    case COLLECTION_ACCESS:
      return "COLLECTION_LINK_PROJECT_ROLE";
    case DATE_PUBLISHED_APP_LAST_VIEWED:
      return HexOrder.APP_ONLY_RECENTLY_VIEWED;
    default:
      return undefined;
  }
};

const convertAgGridSortToOrderByDirection = (
  agGridSortDirection: Exclude<AgGridSortDirection, null>,
  defaultSortDirection: OrderByDirection,
): OrderByDirection => {
  const maybeSortDirection = agGridSortDirection.toUpperCase();
  return OrderByDirection.guard(maybeSortDirection)
    ? maybeSortDirection
    : defaultSortDirection;
};

export const getQueryParamsFromSortModel = (
  {
    colId,
    sort: agGridSortDirection,
  }: {
    colId: ProjectsTableColumnId | null;
    sort: AgGridSortDirection | undefined;
  },
  duration: ViewCountDuration = ViewCountDuration.ALL_TIME,
):
  | {
      sort: PROJECTS_TABLE_SORT_ORDER;
      direction: OrderByDirection;
    }
  | { sort: undefined; direction: undefined } => {
  const sortOrder = getSortOrderFromColumnId(colId, duration);
  if (colId == null || agGridSortDirection == null || sortOrder == null) {
    return { sort: undefined, direction: undefined };
  }

  const sortDirection = convertAgGridSortToOrderByDirection(
    agGridSortDirection,
    getDefaultHexOrderByDirection(sortOrder),
  );

  return { sort: sortOrder, direction: sortDirection };
};
