import { HexId, SpecialVersionType } from "@hex/common";

import { ORG_ID } from "../../../orgs.js";
import { Routes } from "../../../route/routes.js";
import { getHexRoute } from "../../hex-list-2/hex-row/utils.js";

export const getRelativeUrlForExplore = (id: HexId): string => {
  return Routes.EXPLORE.getUrl({ hexId: id });
};

export const getRelativeUrlForProject = (
  route: ReturnType<typeof getHexRoute>,
  id: HexId,
  version: SpecialVersionType,
): string => {
  return route.getUrl({ version, hexId: id });
};

export const getUrlForProject = (
  route: ReturnType<typeof getHexRoute>,
  id: HexId,
  version: SpecialVersionType,
): string => {
  return Routes.href(ORG_ID, true, route, {
    hexId: id,
    version,
  });
};
