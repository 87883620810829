import { ColumnState } from "@ag-grid-community/core";
import { ApolloError } from "@apollo/client";
import { Cursor } from "@hex/common";

import { PageInfo } from "../../generated/graphqlTypes.js";

import { ProjectsTableSafeOrUnknownHexFragment } from "./ProjectsTable.generated.js";

export const RequiredColumn = {
  NAME: "name",
  STATUS: "status",
  CATEGORIES: "categories",
  CREATOR: "creator",
  DATE_CREATED: "dateCreated",
  DATE_LAST_EDITED: "dateLastEdited",
  DATE_LAST_PUBLISHED: "dateLastPublished",
  MY_ACCESS: "myAccess",
  SHARED_WITH: "sharedWith",
} as const;

const OptionalColumn = {
  COLLECTION_ACCESS: "collectionAccess",
  DATE_TRASHED: "dateTrashed",
  APP_VIEWS: "appViews",
  DATE_PUBLISHED_APP_LAST_VIEWED: "datePublishedAppLastViewed",
  // TODO(WORK-1300): Make this field required once the db column is not nullable.
  OWNER: "owner",
  RECENTLY_VIEWED: "lastViewedByMe",
  REQUIRES_REVIEW: "requiresReview",
  SCHEDULED: "scheduled",
  DATE_ARCHIVED: "dateArchived",
  COLLECTIONS: "collections",
} as const;

export const ProjectsTableColumnId = {
  ...RequiredColumn,
  ...OptionalColumn,
} as const;

type ValueOf<Obj> = Obj[keyof Obj];
export type ProjectsTableColumnId = ValueOf<typeof ProjectsTableColumnId>;
type RequiredColIds = ValueOf<typeof RequiredColumn>;

export const arrayOfRequiredColIds = <
  T extends RequiredColIds[] | ProjectsTableColumnId[],
>(
  array: T &
    ([RequiredColIds] extends [T[number]]
      ? unknown
      : [ProjectsTableColumnId] extends [T[number]]
        ? unknown
        : "Missing required columnId"),
): T => array;

export interface ProjectsTableColumnState extends ColumnState {
  colId: ProjectsTableColumnId | string;
}

export type ProjectsTablePersistedGridState<HiddenColId = string> = {
  columnVisibility?: {
    hiddenColIds: (HiddenColId extends string ? HiddenColId : string)[];
  };
};

export type ColumnVisibilityCheckboxState = Record<
  ProjectsTableColumnId,
  { checked: boolean; readonly: boolean }
>;

export type FetchRowDataFn = (args: { after: Cursor | null }) => Promise<{
  error?: ApolloError | null;
  rowData: ProjectsTableSafeOrUnknownHexFragment[] | null;
  pageInfo: PageInfo | null;
}>;
